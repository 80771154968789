<template>
  <div class="notice">
    <Module-Header title="Notice">
      <el-button class="default" @click="$router.push('/notice/add')">Create New</el-button>
    </Module-Header>

    <div class="search-box">
      <el-input placeholder="Keywords" 
        prefix-icon="el-icon-search" v-model="listQ.like_keyword" style="flex: 1"></el-input>

      <Date-picker label="Displayed Time" :joiningDate="date" @updateDate="changeDate"></Date-picker>
    </div>

    <div class="table-box">
      <el-table :data="tableData" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :min-width="its.width" sortable>
             <template  slot-scope="{row}">
               <span v-if="its.prop == 'publicAt'">{{ row.publicAt | formatDateTime }}</span>
               <span v-else>{{ row[its.prop] }}</span>
             </template>
           </el-table-column>
        <el-table-column width="130" align="center">
          <template slot-scope="scope">
            <div class="editor">
              <img src="@/assets/images/edit.png" @click="toEditPage(scope.row.id)" />
              <img src="@/assets/images/delete.png" @click="del(scope.row.id)"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
        @pagination="getData"
      />
    </div>

  </div>
</template>

<script>
import { getNotices, delNotices } from '@/api/notices'
import { _debounce } from '@/utils/throttle'

export default {
  data() {
    return {
      tableCate: [
        {label: 'Title', prop: 'title', width: ''},
        {label: 'Description', prop: 'description', width: ''},
        {label: 'Displayed Time in App', prop: 'displayAt', width: ''},
      ],
      tableData: [],
      total: 5,
      date: '',
      listQ:{
        like_keyword: null,
        start_display_at: null,
        end_display_at: null,
        _start: 1,
        _limit: 20,
      },
      form: {
        keywords: '',
      },
      status:[],
      types:[],
    }
  },
  watch:{
    listQ:{
      handler(val){
        if(val !==null){
          _debounce(this.getData, 500)
        }
      },
      deep:true
    },
  },
  methods: {
    del(id) {
      this.$popUp.config({
        content: 'Please confirm whether to Delete',
        confirm: {
          e: () => {
            delNotices(id).then(() => {
              this.$popUp.removePopup()
              this.getData()
            })
          }
        }
      })
    },
    changeDate(s) {
      this.date = s

      this.listQ.start_display_at = s ? new Date(s).format('yyyy-MM-dd') + ' 00:00:00' : ''
      this.listQ.end_display_at = s ? new Date(s).format('yyyy-MM-dd') + ' 23:59:59' : ''
    },
    getData() {
      let {listQ} = this, form = {
        like_keyword: listQ.like_keyword,
        start_display_at: listQ.start_display_at,
        end_display_at: listQ.end_display_at,
        _start: listQ._start - 1,
        _limit: listQ._limit,
      }
      
      getNotices(form).then(v => {
        // console.log(v)
        v.data.filter(its => {
          its.title = its.title.zhHant
          its.description = its.description.zhHant
          its.displayAt = its.displayAt ? new Date(its.displayAt).format('yyyy/MM/dd hh:mm:ss') : null
        })
        this.tableData = v.data
        this.total = Number(v.headers['x-total-count'])
      })
    },
    toEditPage(id) {
      this.$router.push({ path: `/notice/edit/${id}`})
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.notice {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  .search-box{
    display: flex;
    font-size: 0.875rem;
    width: 100%;
    margin-top: 3.125rem;
  }

}
</style>